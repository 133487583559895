import {
  captureException,
  captureRemixErrorBoundaryError,
  withSentry,
} from "@sentry/remix";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  isRouteErrorResponse,
  useRouteError,
  useNavigation,
  useRouteLoaderData,
} from "@remix-run/react";
import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import i18next from "~/i18next.server";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { ToastContainer } from "react-toastify";
import { twMerge } from "tailwind-merge";
import "virtual:uno.css";
import "@unocss/reset/tailwind-compat.css";
import "react-toastify/dist/ReactToastify.css";
import "~/global.css";
import { AuthContext } from "~/providers/Auth";
import { CartProvider } from "~/providers/Cart";
import { useEffect } from "react";
import { env } from "~/util/env";
import { i18nCookie } from "./cookies";
import Gutter from "~/components/layout/Gutter";
import Heading from "~/components/layout/Heading";
import LoadingBar from "react-top-loading-bar";
import Cookies, { CookieConsentProvider } from "~/providers/Cookies";
import { createClient } from "~/graphql/createClient";
import RootQuery from "~/graphql/RootQuery";
import type { UserFragmentType } from "./graphql/fragments/types";
import { extractTokenFromRequest } from "./util/extractTokenFromRequest";
import { createTitle } from "./util/createTitle";

export function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation();

  captureRemixErrorBoundaryError(error);

  // Track all errors that are not HTTP 4xx in sentry
  if (
    !isRouteErrorResponse(error) ||
    (isRouteErrorResponse(error) && (error.status < 400 || error.status >= 500))
  ) {
    captureException(error);
  }

  useEffect(() => {
    if (env().NODE_ENV === "development") {
      console.log("server error:");
      console.error(error);
    }
  }, [error]);

  return (
    <Gutter className="flex flex-1 flex-col justify-center text-center">
      <Heading>
        {isRouteErrorResponse(error)
          ? `HTTP ${error.status} - ${error.status === 404 ? t("ui.error.pageNotFound") : error.data ?? error.statusText}`
          : "Unknown Error"}
      </Heading>
    </Gutter>
  );
}

export const meta: MetaFunction = () => [{ title: createTitle() }];

export const links: LinksFunction = () => [
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
];

export let handle = {
  i18n: "common",
};

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(request);
  const [res, localeCookie] = await Promise.all([
    createClient(request).query(RootQuery, { locale }),
    i18nCookie.serialize(locale),
  ]);
  const token = extractTokenFromRequest(request);

  if (res.error || !res.data) {
    throw res.error;
  }

  return json({
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      BUILD_NUMBER: process.env.BUILD_NUMBER,
      FRONTEND_URL: process.env.FRONTEND_URL,
      BACKEND_URL: process.env.BACKEND_URL,
      STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
      CDN_CGI_IMAGE_URL: process.env.CDN_CGI_IMAGE_URL,
      USE_CLOUDFLARE_IMAGE_TRANSFORMATIONS:
        process.env.USE_CLOUDFLARE_IMAGE_TRANSFORMATIONS,
      APPLE_DEVELOPER_MERCHANTID_DOMAIN_ASSOCIATION:
        process.env.APPLE_DEVELOPER_MERCHANTID_DOMAIN_ASSOCIATION,
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_RELEASE: process.env.SENTRY_RELEASE,
    } satisfies BrowserEnvironment,
    locale,
    user: res.data.meUser?.user,
    token,
    site: res.data.Site,
    navigation: res.data.Navigation,
    localeCookie,
  });
}

const contextClass = {
  success: "border-green-500 bg-green-100 text-green-700",
  warning: "border-red-500 bg-red-100 text-red-700",
  error: "",
  info: "",
  default: "",
  dark: "",
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const data = useRouteLoaderData<typeof loader>("root");
  const { locale, localeCookie, user, ENV, navigation, site, token } = data ?? {
    locale: i18n.language,
    user: undefined,
    ENV: {},
  };

  useChangeLanguage(locale);

  // Set locale cookie
  useEffect(() => {
    if (!localeCookie) return;
    document.cookie = localeCookie;
  }, [localeCookie]);

  // Update the user's locale in Payload
  useEffect(() => {
    if (user?.id) {
      fetch(`${env().BACKEND_URL}/api/users/${user.id}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
          Connection: "keep-alive",
        },
        body: JSON.stringify({
          locale,
        }),
      });
    }
  }, [locale, user, token]);

  const { state } = useNavigation();

  return (
    <html lang={locale} dir={i18n.dir()} className="font-sans">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
      </head>
      <body>
        <LoadingBar
          color="#ffce0b"
          height={2}
          progress={state === "loading" ? 66 : 100}
        />
        <CookieConsentProvider>
          <AuthContext.Provider
            value={{
              user: user as UserFragmentType | null | undefined,
              token,
            }}
          >
            <CartProvider taxRate={site?.taxRate ?? 0}>
              <main className="flex min-h-[100vh] flex-col">
                <Header navigationItems={navigation?.main} />
                <ToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  toastClassName={(context) =>
                    twMerge(
                      "relative flex min-h-0 rounded justify-between overflow-hidden cursor-pointer bg-white border-gray-500 shadow p-4 text-black text-sm font-medium",
                      contextClass[context?.type || "default"],
                    )
                  }
                  icon={false}
                  closeButton={true}
                />
                <div className="flex min-h-[100%] flex-1 flex-col">
                  {children}
                </div>
              </main>
              <Footer navigationItems={navigation?.footer} />
              <ScrollRestoration />
              <Scripts />
              <Cookies />
            </CartProvider>
          </AuthContext.Provider>
        </CookieConsentProvider>
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
